html {
  @apply bg-gray-100;
}

:root {
  --amplify-primary-color: #d71440;
  --amplify-primary-tint: #ba112d;
  --amplify-primary-shade: #ba112d;
}

.login-bg {
  background-image: linear-gradient(rgba(70, 70, 70, 0.75), rgba(255, 255, 255, 0.45)), url("../images/branch-bg.jpg");
  background-size: cover;
}

.resolution-size{
  width: 720px;
  height: 1280px;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 80vh;
}
